h1,
h2 {
    font-size: 4rem;
    margin: 1%;
}

h3,
p {
    margin-bottom: 3%;
}

.sub-title, .meet-title {
  font-family: "Londrina Sketch", sans-serif;
  color: #ff1493;
  letter-spacing: 3px;
}

.team-section {
    padding: 20px;
    width: 80%;
    margin: auto;
    border-radius: 10px;
    background-color: #e5e5db;
    text-align: center;
}

.team-row {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 30px;
}


.team-member img {
    width: 200px;
    height: 200px;
    border-radius: 10%;
    object-fit: cover;
    margin-bottom: 10px;
}

.scrollable-row {
    display: flex;
    overflow-x: hidden;
    gap: 20px;
    padding: 10px 0;
    white-space: nowrap;
}

.team-member {
    text-align: center;
    flex: 0 0 auto;
}


@media (max-width: 668px) {
    .team-section {
        width: 95%;
    }   
    .team-member img {
        width: 300px;
        height: 300px;
    }
}