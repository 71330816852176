@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-style: normal;
}

/* Grievance Navigation */
.grievance-portal-nav {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.AllGrivance-button-container,
.GrievanceForm-button-container {
  margin: 0 10px;
}

.AllGrivance-button,
.GrievanceForm-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s ease;
}

.AllGrivance-button:hover,
.GrievanceForm-button:hover {
  background-color: #0056b3;
}

/* Grievance Display */

.grievance-display {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  font-family: "Raleway", serif !important;
}

/* Card Styling */
.brutalist-card {
  min-width: 40%;
  max-width: 82%;
  border: 4px solid #000;
  background-color: #fff;
  padding: 1%rem;
  box-shadow: 10px 10px 0 #000;
  align-self: self-start;
  margin-bottom: 20px; /* Add some space between cards */
}

.brutalist-card__header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #000;
  padding-bottom: 1rem;
}

.brutalist-card__icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 0.5rem;
}

.brutalist-card__icon svg {
  height: 1rem;
  width: 1rem;
  fill: #fff;
}

.brutalist-card__subject {
  font-weight: 900;
  color: #000;
  font-size: 1rem;
  text-transform: uppercase;
}

.brutalist-card__alert {
  font-weight: 900;
  color: #000;
  font-size: 1rem;
  text-transform: uppercase;
}

.brutalist-card__message {
  margin-top: 1rem;
  color: #000;
  font-size: 0.9rem;
  line-height: 1.4;
  border-bottom: 2px solid #000;
  padding-bottom: 1rem;
  font-weight: 600;
}

.upvote-true,
.downvote-true {
  background-color: #4f2067 !important;
  border-color: #1e0d20 !important;
  color: #fff !important;
  box-shadow: 4px 4px 0 #004280 !important;
}

@media (max-width: 1024px) {
  .grievance-portal-nav {
    display: flex;
    gap: 20px;
  }
}

@media (max-width: 512px) {
  .grievance-portal-nav {
    display: flex;
    gap: 20px;
  }
}

.brutalist-card__actions {
  display: flex; /* Align items horizontally */
  gap: 5px; /* Space between buttons */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: center;
  flex-direction: row;
}

.brutalist-card__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  font-size: 0.7rem;
  font-weight: 400;
  flex: 1;
  text-transform: uppercase;
  border: 10px solid #000;
  background-color: #fff;
  color: #000;
  position: relative;
  transition: all 0.2s ease;
  box-shadow: 2px 2px 0 #000;
  overflow: hidden;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.brutalist-card__button--resolved {
  background-color: #28a745; /* Green */
  color: white;
}

.brutalist-card__button--remove {
  background-color: #dc3545; /* Red */
  color: white;
}

@media (max-width: 768px) {
  .brutalist-card__button {
    font-size: 1.1rem; /* Increase icon size on mobile */
  }

  /* Hide text on small screens and show only the icon */
  .brutalist-card__button span {
    display: none;
  }

  .brutalist-card__button svg {
    font-size: 1.1rem; /* Adjust icon size */
  }
}

/* Parent container for the sticky header */
.sticky-header-container {
  position: sticky;
  top: 0;
  z-index: 10; /* Keep it on top of other content */
  padding-bottom: 10px; /* Space below the container */
  background-color: #2b2a2a;
  border-radius: 10px 15px;
  margin: auto;
  margin-bottom: 5px;
}

/* Container for the title */
.query-corner-container {
  padding: 20px;
  border-radius: 8px;
  margin: 0 auto; /* Center the div */
  text-align: center;
}

/* Title styling */
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

.query-corner-title {
  font-size: 2rem;
  color: #e0d9d9; /* Dark text color */
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  font-family: "Lilita One", serif;
  letter-spacing: 2.5px; /* Slight letter spacing */
}

/* Grievance Navigation */
.grievance-portal-nav {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}

/* Navigation Button Styling */
.AllGrivance-button-container,
.GrievanceForm-button-container {
  margin: 0 10px;
}

.AllGrivance-button,
.GrievanceForm-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s ease;
}
