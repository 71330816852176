@import url('https://fonts.googleapis.com/css2?family=Ysabeau+SC:wght@1..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.parent-box {
  padding-top: 5%;
  font-family: "Raleway", serif;
}

.login-box {
  width: 400px;
  padding: 40px;
  margin: auto;
  background: rgba(0, 0, 0, .5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
  border-radius: 10px;
}

.profile-topic {
  font-family: "Ysabeau SC", serif;
  font-size: 2.4rem;
  color: #f87171;
}

.login-box h2 {
  padding: 0;
  font-family: "Ysabeau SC", serif;
  font-size: 2.2rem;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #03e9f4;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus~label,
.login-box .user-box input:valid~label {
  top: -20px;
  left: 0;
  color: #03e9f4;
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #03e9f4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px
}

.below-login-link {
  margin-right: 20px;
  color: #03e9f4;
  font-size: 12px;
  text-decoration: none;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 20px;

}

.below-login-link:hover {
  transform: scale(1.1);
  text-decoration: underline;
}

.login-button {
  position: relative;
  display: inline-block;
  color: #fff;
  text-align: center;
  padding: 10px 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #23a2a9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, filter 0.3s ease;
}

.login-button:hover {
  background-color: #029db0;
  filter: brightness(70%);
}

.login-button-inbtw {
  position: relative;
  display: inline-block;
  color: #fff;
  text-align: center;
  padding: 10px 30px;
  margin-bottom: 30px;
  margin-top: 0px;
  background-color: #23a2a9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, filter 0.3s ease;
}

.login-button-inbtw:hover {
  background-color: #029db0;
  filter: brightness(70%);
}

@media (max-width: 425px) {
  .login-box {
    width: 90%;
    padding: 20px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .5);
  }

  .login-box h2 {
    font-size: 20px;
  }

  .login-box .user-box input {
    font-size: 14px;
  }

  .login-box .user-box label {
    font-size: 14px;
  }

  .login-button {
    padding: 8px 20px;
    font-size: 14px;
  }

  .below-login-link {
    font-size: 10px;
    margin-right: 10px;
  }
}

.profile-container {
  display: flex;
  height: 100vh; // Full height of the screen
  color: #fff;
}

.profile-sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.2);
}

.profile-header {
  width: 100%;
  text-align: center;
}

.profile-details h1 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.profile-details p {
  margin: 5px 0;
  font-size: 1rem;
  opacity: 0.8;
}

.logout-icon {
  font-size: 2rem;
  color: #f87171;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
  margin-top: 10px;
}

.logout-icon:hover {
  transform: scale(1.2);
  color: #ef4444;
}

.grievances-section {
  width: 100%;
}

.grievances-title {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
}

.grievances-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.grievance-item {
  background-color: #475569;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  transition: transform 0.2s ease, background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.grievance-item:hover {
  transform: translateY(-5px);
  background-color: #64748b;
}

.grievance-head {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.grievance-content {
  font-size: 1rem;
  margin-bottom: 10px;
}

.grievance-status {
  font-size: 0.9rem;
  padding: 0.5rem;
  border-radius: 7px 12px;
  display: inline-block;
}

.grievance-status.pending {
  background-color: #f59e0b; /* Orange */
  color: #fff;
}

.grievance-status.approved {
  background-color: #10b981; /* Green */
  color: #fff;
}

.grievance-status.rejected {
  background-color: #ef4444; /* Red */
  color: #fff;
}

.grievance-status.removed {
  background-color: #92400e; /* Gray */
  color: #fff;
}

.grievance-status.resolved {
  background-color: #3b82f6; /* Blue */
  color: #fff;
}

.rejected-reason {
  font-size: 0.8rem;
  color: #f87171;
  margin-top: 5px;
  opacity: 0.8;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .profile-sidebar {
    max-width: 100%;
    padding: 10px;
  }

  .profile-details h1 {
    font-size: 1.5rem;
  }

  .profile-details p {
    font-size: 0.9rem;
  }

  .logout-icon {
    font-size: 1.8rem;
  }

  .grievances-title {
    font-size: 1.2rem;
  }

  .grievance-item {
    padding: 10px;
  }

  .grievance-head {
    font-size: 1rem;
  }

  .grievance-content {
    font-size: 0.9rem;
  }

  .grievance-status {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .profile-header {
    margin-bottom: 15px;
  }

  .profile-details h1 {
    font-size: 1.2rem;
  }

  .profile-details p {
    font-size: 0.8rem;
  }

  .grievances-title {
    font-size: 1rem;
  }

  .grievance-item {
    padding: 8px;
  }

  .grievance-head {
    font-size: 0.9rem;
  }

  .grievance-content {
    font-size: 0.8rem;
  }

  .grievance-status {
    font-size: 0.7rem;
  }
}


.logout-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #ef4444;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.logout-button:hover {
  background-color: #dc2626;
  transform: scale(1.05);
}

.change-password-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #3b82f6;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.change-password-button:hover {
  background-color: #2563eb;
  transform: scale(1.05);
}

/* FeedbackLink.css */

/* Button Style */
.feedback-button {
  background-color: #007BFF;
  color: #FFFFFF;
  padding: 12px 24px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.feedback-button:hover {
  background-color: #0056B3;
}

/* Banner Style (Alternative) */
.feedback-container {
  color: #333333;
  padding: 16px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
}

.feedback-container a {
  text-decoration: none;
  font-weight: bold;
}

/* Floating Button Style */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #FFFFFF;
  padding: 12px 24px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.floating-button:hover {
  background-color: #0056B3;
}

.loading-message,
.no-posts-message {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
  margin-top: 20px;
}

.loading-message {
  font-style: italic;
}

.no-posts-message {
  font-weight: bold;
}

/* Style for the dropdown container */
.dropdown-container {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

/* Style for the dropdown input field */
.dropdown-container input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  cursor: not-allowed;
}

/* Style for the actual dropdown select element */
.dropdown-container select {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-top: 5px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  cursor: pointer;
}

/* Style for the dropdown options */
.dropdown-container select option {
  background-color: #333;
  color: #fff;
  padding: 10px;
  font-size: 16px;
}

/* Hover effect for the select options */
.dropdown-container select option:hover {
  background-color: #029db0;
}
