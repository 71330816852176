* {
  font-style: normal;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.grievance-display-container {
  padding: 10px;
}

.grievance-display {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

/* Card Styling */
.brutalist-card {
  border: 4px solid #000;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 10px 10px 0 #000;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.brutalist-card__message {
  margin-top: 1rem;
  color: #000;
  font-size: 0.9rem;
  line-height: 1.4;
  border-bottom: 2px solid #000;
  padding-bottom: 1rem;
  font-weight: 600;
}

.brutalist-card__actions {
  margin-top: 1rem;
}

.brutalist-card__button {
  display: block;
  width: 100%;
  border: 3px solid #000;
  background-color: #fff;
  color: #000;
  position: relative;
  transition: all 0.2s ease;
  overflow: hidden;
  text-decoration: none;
  margin-bottom: 1rem;
}

.brutalist-card__button--read {
  background-color: #000;
  color: #fff;
}

.brutalist-card__button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: all 0.6s;
}

.brutalist-card__button:hover::before {
  left: 100%;
}

.brutalist-card__button:hover {
  transform: translate(-2px, -2px);
  box-shadow: 5px 5px 0 #000;
}

.brutalist-card__button--mark:hover {
  background-color: #296fbb;
  border-color: #296fbb;
  color: #fff;
  box-shadow: 5px 5px 0 #004280;
}

.brutalist-card__button--read:hover {
  background-color: #ff0000;
  border-color: #ff0000;
  color: #fff;
  box-shadow: 5px 5px 0 #800000;
}

.brutalist-card__button:active {
  transform: translate(5px, 5px);
  box-shadow: none;
}

.show-identity-clicked {
  background-color: blue !important;
  color: white;
}

.Reject-textarea {
  width: 100%;
  height: 100px;
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .grievance-display-container {
    padding: 5px;
  }

  .grievance-display {
    gap: 10px;
  }

  .brutalist-card {
    padding: 0.75rem;
    margin-bottom: 15px;
  }

  .brutalist-card__subject {
    font-size: 0.9rem;
  }

  .brutalist-card__alert {
    font-size: 1.1rem;
  }

  .brutalist-card__message {
    font-size: 0.8rem;
  }

  .brutalist-card__button {
    font-size: 0.7rem;
    padding: 0.2rem;
  }

  .Reject-textarea {
    height: 80px;
  }
}

@media (max-width: 480px) {
  .grievance-display-container {
    padding: 5px;
  }

  .grievance-display {
    gap: 5px;
  }

  .brutalist-card {
    padding: 0.5rem;
    margin-bottom: 10px;
  }

  .brutalist-card__subject {
    font-size: 0.8rem;
  }

  .brutalist-card__alert {
    font-size: 1rem;
  }

  .brutalist-card__message {
    font-size: 0.75rem;
  }

  .brutalist-card__button {
    font-size: 0.5rem;
    padding: 0.2rem;
  }

  .Reject-textarea {
    height: 60px;
  }
}
